import * as React from "react";
import Layout from "../components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { graphql } from "gatsby";
import "simplebar-react/dist/simplebar.min.css";
import BasicPage from "../components/basicPage";
import styled from "styled-components";

const Wrapper = styled.div`

h2 {
  color: #7ef6fc;
  margin-bottom: 5px;
}
h3 {
  margin-top: 5px;
}
`

const AboutTemplatePage = ({ location, data }) => {
  return (
    <Layout path={location.pathname}>
      <GatsbySeo
        title={data.sanityAbout.seo.title}
        description={data.sanityAbout.seo.description}
      />
      <Wrapper>
      <BasicPage page={data.sanityAbout} noScrollIndic/> 
      </Wrapper>
    </Layout>
  );
};

export const query = graphql`
  query AboutTemplateQuery {
    sanityAbout {
      title
      subtitle
      content: _rawContent
      buttontext
      link: _rawButtonlink(resolveReferences: { maxDepth: 10 })
      image {
        asset {
          url
          altText
          gatsbyImageData(layout: FIXED, placeholder: BLURRED)
        }
      }
      seo {
        title
        description
      }
    }
  }
`;

export default AboutTemplatePage;
